@import "../../../scss/configurations";
#manage-device-client-group-device-clients-modal {
  .link-holder {
    margin-right: map-get($spacers, 2);

    strong {
      margin-right: map-get($spacers, 1);
    }
  }
}
