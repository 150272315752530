@import "../../../scss/styles";

.import-csv-modal {
  .email-cell {
    display: flex;
    align-items: center;
    .value {
      margin-right: map-get($spacers, 2);
    }
    .btn {
      margin-left: auto;
    }
  }
  .original,
  .mapping,
  .indicator {
    display: block;
    text-align: center;
  }
  .indicator {
    margin-left: auto;
    margin-right: auto;
  }
  .remove-users-btn {
    float: right;
    margin-top: map-get($spacers, 2);
  }
  .file-name {
    word-wrap: break-word;
    word-break: break-word;
  }
  .import-issues,
  .file-details {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    > ul {
      flex-grow: 1;
      margin-top: auto;
      margin-bottom: auto;
      > li {
        .import-issue {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          > .value {
            margin-right: map-get($spacers, 1);
          }
          > .btn {
            margin-left: map-get($spacers, 2);
            margin-top: map-get($spacers, 1);
            margin-bottom: map-get($spacers, 1);
          }
        }
      }
    }
  }
  .required-mapping {
    @extend .badge;
    @extend .rounded-pill;
    background-color: $danger;
    color: var(--duke-danger-fg, var(--#{$prefix}badge-color));
    &.mapped {
      background-color: $success;
      color: var(--duke-success-fg, var(--#{$prefix}badge-color));
    }
    .icon {
      margin-right: map-get($spacers, 1);
      color: var(--duke-icon-color-for-bg, inherit);
    }
  }
}
.import-csv-modal .react-bootstrap-table {
  // match error color to that of alert
  $error-background: shift-color($danger, $table-bg-scale);
  $error-color: shift-color($danger, $alert-color-scale);
  @if (contrast-ratio($error-background, $error-color) < $min-contrast-ratio) {
    $error-color: mix(
      $danger,
      color-contrast($error-background),
      abs($alert-color-scale)
    );
  }
  .table-bordered > :not(caption) > * > th {
    text-transform: none;
    .indicator {
      opacity: 0.6;
    }
    .original {
      opacity: 0.6;
      font-size: $small-font-size;
    }
    .mapping {
      font-size: $font-size-lg;
    }
    &.not-mapped {
      color: $text-muted;
    }
    .btn-unstyled {
      width: 100%;
    }

    &.error {
      @extend .table-danger;
      border-color: $danger;

      .duke-header-cell {
        color: $error-color;
        // visually this is a border, so to be included regardless of enable shadow
        box-shadow: 0px -1px 0px $danger, 1px 0px 0px $danger,
          0px 1px 0px $danger, -1px 0px 0px $danger;
      }
      & + th {
        border-left-color: $danger;
        .duke-header-cell {
          // visually this is a border, so to be included regardless of enable shadow
          box-shadow: 0px -1px 0px $table-border-color,
            1px 0px 0px $table-border-color, 0px 1px 0px $table-border-color,
            -1px 0px 0px $danger;
        }
      }
    }
  }
  tr.error td,
  tr.error-row td.error {
    @extend .table-danger;
    color: $error-color;
  }
  td.error,
  tr.error-row td.error {
    border-color: $danger;
    border-bottom-color: $danger;
    border-bottom-width: $table-border-width;
  }
  tr.error-row td:not(:last-child):not(.error) {
    border-right: 0 none transparent;
  }
  td.not-mapped {
    text-decoration: line-through;
    color: $text-muted;
  }
}
.dropdown-item.mapped:not(.active) {
  background-color: rgba($success, 0.2);
}
