@import "../../scss/configurations";

@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.css";
@import "~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.css";

/**
 * Defines the highlight color used to indicate drag and drop
 */
$duke-dnd-target-color: $primary !default;
/**
 * Defines the highlight border used to indicate drag and drop
 */
$duke-dnd-border: 2px dashed !default;

// separate block as the feed back is outside of table component dom
.duke-table + .invalid-feedback {
  margin-top: map-get($spacers, 3) * -1;
  margin-bottom: map-get($spacers, 3);
}

.duke-table {
  max-width: 100%;
  margin-bottom: $spacer;
  display: flex;
  flex-direction: column;
  position: relative;

  //<editor-fold desc="generate modifier classes for max height">

  $rowCounts: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25;
  @each $rowCount in $rowCounts {
    &.max-rows-#{$rowCount} {
      .react-bootstrap-table-wrapper {
        overflow: auto;
        // + one to make room for the header
        max-height: ($rowCount + 1) *
          (($table-cell-padding-y * 2) + $line-height-base);
      }
      &.horizontal-scrolling .react-bootstrap-table-wrapper {
        // + horizontal scrollbar height
        max-height: ($rowCount + 1) *
          (($table-cell-padding-y * 2) + $line-height-base) +
          1rem;
      }
    }
    &.duke-table-compact.max-rows-#{$rowCount} {
      .react-bootstrap-table-wrapper {
        // + one to make room for the header
        max-height: ($rowCount + 1) *
          (($table-cell-padding-y-sm * 2) + $line-height-base);
      }

      &.horizontal-scrolling .react-bootstrap-table-wrapper {
        // + one to make room for the header
        max-height: ($rowCount + 1) *
          (($table-cell-padding-y-sm * 2) + $line-height-base) +
          1rem;
      }
    }
  }
  //</editor-fold>

  //<editor-fold desc="infinite scroll sensors and indicators">
  .infinite-scroll-sensor {
    display: block;
    position: sticky;
    left: 0;
    z-index: 0;
    flex-shrink: 0;
    text-align: center;
    color: $text-muted;
  }
  .rendering-more .rendering-less {
    height: 1px;
    margin-bottom: -1px;
  }
  .end-reached {
    padding-top: map-get($spacers, 2);
  }
  .rendering-more {
    font-size: $font-size-lg;
    min-height: $table-cell-padding-y + $line-height-base;
    // this is kind of like hidden offset delta, ie event triggers 200px before item is visible
    padding-top: calc(200px + #{map-get($spacers, 2)});
    margin-top: -200px;
    .loading-indicator {
      display: block;
    }
  }
  &.duke-table-compact {
    .rendering-more {
      font-size: $font-size-base;

      .btn {
        @include button-size(
          $btn-padding-y-sm,
          $btn-padding-x-sm,
          $btn-font-size-sm,
          $btn-border-radius-sm
        );
      }
    }

    .end-reached {
      font-size: $font-size-sm;
    }
  }
  //</editor-fold>

  table {
    // without this the 100% content size will not work
    height: 1px;
  }
  th,
  td {
    position: relative;
  }
  .table {
    margin-bottom: 0;
    & > :not(:first-child) {
      // override default bs border, as its useless with fixed header
      border-top: 0 transparent none;
    }
    & > tbody + * {
      // restore the default border for element following the first body
      border-top: $table-border-width solid;
      border-top-color: $table-group-separator-color;
    }
    & > thead th:before {
      content: "";
      // restore the default border for the thead th elements, so it is visible even when scrolling
      border-bottom: $table-border-width solid;
      border-bottom-color: $table-group-separator-color;
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;

      @include box-shadow($box-shadow-sm);
    }

    & > thead th {
      &.active-sort,
      &.error,
      &[class*="border-"] {
        &:before {
          left: 0;
        }
      }
    }

    & > :not(caption) > * > th {
      // without this the 100% content size will not work
      // th padding refactored into the contents duke-cell
      padding: 0;
      &:focus {
        outline: $pagination-focus-outline;
        box-shadow: $pagination-focus-box-shadow;
        outline: 3px solid $input-btn-focus-color;
        outline-offset: -3px;
      }
    }
  }

  .row-tools-cell {
    /* row-tools content is a font awesome icon with width set to 1.25em. we must set the column width to match*/
    width: calc(1.25em + #{$table-cell-padding-x} * 2);
  }
  .row-disabled,
  .table-hover > tbody > tr.row-disabled {
    &,
    &:hover {
      color: $text-muted;
    }
  }
  .table-striped.table-hover {
    & > tbody > tr.row-disabled:hover:nth-of-type(#{$table-striped-order}) {
      background-color: $table-accent-bg;
    }
  }

  .duke-table-holder,
  .react-bootstrap-table-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .react-bootstrap-table-wrapper {
    position: relative;
    & .table {
      // The sticky positioning below with scrolling will not work without this
      overflow: visible !important;
      & > :not(caption) > * > th {
        position: sticky;
        z-index: 10;
        .modal-body & {
          top: -1 * $modal-inner-padding;
        }
      }
    }
  }
  .react-bootstrap-table {
    flex-grow: 1;
    flex-shrink: 1;
    // ensures that the infinite scroll helpers stay under the table
    position: relative;
    z-index: 1;

    .table-bordered > :not(caption) > * > th {
      .duke-header-cell {
        // visually this is a border, so to be included regardless of enable shadow
        box-shadow: 0px -1px 0px $table-border-color,
          1px 0px 0px $table-border-color, 0px 1px 0px $table-border-color,
          -1px 0px 0px $table-border-color;
      }

      &.active-sort {
        border-color: $component-active-bg;
        box-shadow: 1px 0 0 0 $component-active-bg inset,
          -1px 0 0px 0px $component-active-bg inset,
          0px 1px 0px 0px $component-active-bg inset,
          0px -1px 0px 0px $component-active-bg inset;
      }
    }
  }

  &.horizontal-scrolling {
    .react-bootstrap-table-wrapper {
      overflow: auto;
      position: relative;
      table {
        table-layout: auto;
      }
      .duke-cell {
        white-space: nowrap;
      }
    }
  }

  //<editor-fold desc="Invalid styles">
  &.invalid {
    .react-bootstrap-table .table-bordered > :not(caption) > * > th {
      .duke-header-cell {
        // visually this is a border, so to be included regardless of enable shadow
        box-shadow: 1px 1px 0px $table-border-color,
          -1px 0px 0px $table-border-color, 0px -1px 0px $danger;
      }
      &:first-child .duke-header-cell {
        box-shadow: 1px 0px 0px $table-border-color, -1px 0px 0px $danger,
          0px -1px 0px $danger, 0px 1px 0px $table-border-color;
      }
      &:last-child .duke-header-cell {
        box-shadow: 1px 0px 0px $danger, -1px 0px 0px $table-border-color,
          0px -1px 0px $danger, 0px 1px 0px $table-border-color;
      }
      &:only-child .duke-header-cell {
        box-shadow: 1px 0px 0px $danger, -1px 0px 0px $danger,
          0px -1px 0px $danger, 0px 1px 0px $table-border-color;
      }
    }
  }
  &.invalid .table-bordered > :not(caption) > * > * {
    &:first-child {
      border-left-color: $danger;
    }
    &:last-child {
      border-right-color: $danger;
    }
  }
  &.invalid .table-bordered > :not(caption) > * > th {
    border-top-color: $danger;
  }
  &.invalid .table-bordered > tbody > tr:last-child {
    border-bottom-color: $danger;
  }
  // </editor-fold>
  .selection-cell,
  .selection-cell-header {
    text-align: center;
    .form-check {
      // we want to align this to center
      display: inline-block;
      // by default the BS checkbox includes the "empty space" usually shown between the box and its label, which
      // is a stupid choice as it prevents aligning checkboxes without labels.
      padding-left: $form-check-input-width;
      .form-check-input {
        margin-left: $form-check-input-width * -1;
      }
    }
  }
  .duke-header-cell {
    cursor: pointer;
    height: 100%;
    .duke-cell,
    .duke-tool-cell {
      padding: $table-cell-padding-y $table-cell-padding-x;
    }
    &.is-draggable {
      cursor: grab;
    }
  }
  &.duke-table-compact .duke-header-cell {
    .duke-cell,
    .duke-tool-cell {
      padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
    }
  }
  .duke-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    &.has-click-handler {
      cursor: pointer;
    }
  }

  //<editor-fold desc="Selection related">
  .react-bootstrap-table .select-indicator {
    font-size: $small-font-size;
    &.checked {
      color: $success;
    }
    &.unchecked {
      color: $danger;
    }
  }
  .react-bootstrap-table th[data-row-selection] {
    width: calc(#{$form-check-input-width} + #{$table-cell-padding-x} * 2);
  }
  .duke-table-compact .react-bootstrap-table th[data-row-selection] {
    width: calc(#{$form-check-input-width} + #{$table-cell-padding-x-sm} * 2);
  }
  .react-bootstrap-table .select-control.indeterminate .form-check-input {
    &[type="radio"] {
      background-color: $form-check-input-checked-bg-color;
      background-image: escape-svg($form-check-radio-checked-bg-image);
    }
    &[type="checkbox"] {
      background-color: color-mix(in srgb, $form-check-input-checked-bg-color, $form-check-input-bg 50%);
      background-image: escape-svg($form-check-input-checked-bg-image);
    }
  }
  //</editor-fold>

  //<editor-fold desc="header">
  .duke-table-header {
    padding-top: map-get($spacers, 2);
    padding-bottom: map-get($spacers, 2);
    margin-top: -1 * map-get($spacers, 1);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .duke-table-footer {
    text-align: right;
    color: $text-muted;
  }
  .form-group .duke-table-header {
    padding-top: 0;
  }
  .duke-table-header .form-check {
    margin-bottom: 0;
  }
  .duke-table-header .form-label {
    margin-bottom: 0;
    padding-top: map-get($spacers, 1);
  }
  .duke-table-header > * {
    & {
      margin-top: map-get($spacers, 1);
      margin-left: map-get($spacers, 1);
      margin-right: map-get($spacers, 1);
    }
    &.btn-group,
    &.btn-toolbar {
      align-self: stretch;
    }
    &:first-child:not(.duke-table-header-tools) {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .duke-table-header {
    .btn-group,
    .btn-toolbar {
      &:empty {
        display: none;
      }
    }
    .btn-toolbar .btn-group {
      &:not(:last-child) {
        margin-right: map-get($spacers, 1);
      }
    }
  }

  .duke-table-header-tools {
    // allows for the input and btn to align and have equal height
    display: flex;
    margin-top: 0;
    &,
    .duke-table-header > &:first-child {
      margin-left: auto;
    }
    flex-wrap: wrap;
    justify-content: flex-end;
    & .duke-apply-input {
      // Allows for the content to grow to match that of btns when used in flex container with button groups
      display: flex;
    }
    & > * {
      margin-top: map-get($spacers, 1);
      margin-left: map-get($spacers, 1);
    }
  }
  .no-data-indicator-cell {
    .duke-table & {
      padding: map-get($spacers, 4);
      .icon {
        margin-bottom: map-get($spacers, 4);
        padding-bottom: map-get($spacers, 2);
      }
      h4 + p {
        margin-top: map-get($spacers, 3);
      }
      .btn {
        @include button-size(
                        $btn-padding-y-lg,
                        $btn-padding-x-lg,
                        $btn-font-size-lg,
                        $btn-border-radius-lg
        );
        margin-left: map-get($spacers, 2);
        margin-right: map-get($spacers, 2);
        margin-top: map-get($spacers, 2);
      }
    }
    .duke-table-compact & {
      font-size: 0.75rem;
      padding: map-get($spacers, 2);
      .icon {
        margin-bottom: map-get($spacers, 2);
        padding-bottom: map-get($spacers, 1);
      }
      h4 {
        font-size: $h4-font-size * 0.75;
      }
      h4 + p {
        margin-top: map-get($spacers, 1);
      }
      .btn {
        @include button-size(
                        $btn-padding-y-sm,
                        $btn-padding-x-sm,
                        $btn-font-size-sm,
                        $btn-border-radius-sm
        );
        margin-left: map-get($spacers, 1);
        margin-right: map-get($spacers, 1);
        margin-top: map-get($spacers, 1);
      }
    }
  }

  //</editor-fold>
}

//<editor-fold desc="DnD">
.drag-target-indicator {
  position: absolute;
  left: -1px;
  top: 0;
  width: 0px;
  height: 100%;
  border-left: $duke-dnd-border $duke-dnd-target-color;
  @include box-shadow(0 0 0.5rem $duke-dnd-target-color);
  z-index: $zindex-popover;
}
.drag-source-mask {
  position: absolute;
  left: -1px;
  top: 0;
  height: 100%;
  background-color: rgba($table-bg, 0.5);
  border: $duke-dnd-border $table-border-color;
  box-shadow: 0 0 0.5rem $table-border-color;
  z-index: $zindex-sticky - 1;
}

//</editor-fold>

.duke-column-tools {
  color: $text-muted;
  position: absolute;
  right: 0em;
  bottom: 0em;
  .duke-table-compact & {
    right: 0em;
    bottom: 0em;
  }
}
.duke-column-tools + .duke-cell {
  // width of the FA fw (=1.25em) defines the required space, that should fit within the margin + padding
  margin-right: calc(1.25em - #{$table-cell-padding-x});
}

.duke-sort {
  height: calc(1em + #{map-get($spacers, 1)} * 2);
  display: block;
  cursor: pointer;
}
.duke-sort {
  .svg-inline--fa {
    opacity: 0.5;
    &.active {
      opacity: 1;
      color: $component-active-bg;
    }
  }
}
.table > :not(caption) > * > td.active-sort .duke-cell {
  // ensures that the cell content stays actionable, above the active-sort visualisation
  position: relative;
  z-index: 1;
}
.table > :not(caption) > * > td.active-sort:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.table > :not(caption) > * > td.active-sort:before {
  box-shadow: 1px 0 0 0 $component-active-bg inset,
    -1px 0 0px 0px $component-active-bg inset;
}
.table > :not(caption) > tr:first-child > td.active-sort:before {
  box-shadow: 1px 0 0 0 $component-active-bg inset,
    -1px 0 0px 0px $component-active-bg inset,
    0px 1px 0px 0px $component-active-bg inset;
}

.table > :not(caption) > tr:last-child > td.active-sort:before {
  box-shadow: 1px 0 0 0 $component-active-bg inset,
    -1px 0 0px 0px $component-active-bg inset,
    0px -1px 0px 0px $component-active-bg inset;
}
.table > :not(caption) > tr:only-child > td.active-sort:before {
  box-shadow: 1px 0 0 0 $component-active-bg inset,
    -1px 0 0px 0px $component-active-bg inset,
    0px 1px 0px 0px $component-active-bg inset,
    0px -1px 0px 0px $component-active-bg inset;
}

.dropdown-item.custom-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
