@import "../../scss/configurations";

/**
 * Horizontal padding for the site content
 */
$duke-page-padding-x: map-get($spacers, 4) !default;

#localeNotAvailablePrompt,
#restoreStoredLocaleNotification {
  position: fixed;
  z-index: $zindex-fixed;
  right: $duke-page-padding-x;
  margin-top: map-get($spacers, 2);
  top: 0;
}
