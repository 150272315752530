@import "../../../scss/configurations";

.no-data-indicator-cell {
  color: $text-muted;

  padding: map-get($spacers, 4);

  .icon {
    margin-bottom: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 2);
  }

  h4,
  p {
    padding-bottom: 0;
  }

  h4 + p {
    margin-top: map-get($spacers, 4);
  }

  .btn {
    @include button-size(
      $btn-padding-y-lg,
      $btn-padding-x-lg,
      $btn-font-size-lg,
      $btn-border-radius-lg
    );
  }
  .btn + .btn {
    margin-left: map-get($spacers, 2);
  }

  .loadFailed {
    color: var(--#{$prefix}danger-text-emphasis);
  }

  .duke-table-compact & {
    font-size: $small-font-size;
    padding: map-get($spacers, 2);
    .icon {
      margin-bottom: map-get($spacers, 2);
      padding-bottom: map-get($spacers, 1);
    }
    h4 {
      font-size: $h4-font-size * 0.875; //matches default $small-font-size size reduction
    }
    h4 + p {
      margin-top: map-get($spacers, 2);
    }
    .btn {
      @include button-size(
        $btn-padding-y-sm,
        $btn-padding-x-sm,
        $btn-font-size-sm,
        $btn-border-radius-sm
      );
    }
  }
}
