@import "../../../scss/styles";
#license-usage-modal {
  .name-column {
    &:not(.with-seats) {
      @extend .w-xl-80;
      @extend .w-lg-70;
      @extend .w-md-60;
      @extend .w-sm-50;
    }
    &.with-seats {
      @extend .w-xl-60;
      @extend .w-lg-50;
      @extend .w-md-40;
      @extend .w-sm-33;
    }

    .link-holder {
      margin-right: map-get($spacers, 2);

      strong {
        margin-right: map-get($spacers, 1);
      }
    }
  }

  .duke-table {
    .indicator {
      &.indicator-active {
        &,
        & .icon {
          color: var(--#{$prefix}success-text-emphasis);
        }
      }

      &.indicator-inactive {
        &,
        & .icon {
          color: var(--#{$prefix}danger-text-emphasis);
        }
      }
    }
  }

  .header-content {
    padding-top: map-get($spacers, 1);

    .seats {
      margin-left: map-get($spacers, 2);

      &.no-seats {
        color: var(--#{$prefix}danger-text-emphasis);
      }
    }
  }
  .usage-details {
    font-size: 60%;
    &.no-usage {
      font-size: 100%;
      &,
      & .icon {
        color: var(--#{$prefix}danger-text-emphasis);
      }
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    strong {
      &,
      & .icon {
        color: var(--#{$prefix}success-text-emphasis);
      }
      margin-right: map-get($spacers, 1);
    }
    & + .usage-details {
      margin-top: map-get($spacers, 2);
    }
    .tooltip & {
      font-size: 100%;
    }
  }
}
// selector prefixed to avoid bleeding out styles, tooltip can't be wrapped with main container as it is in body
.tooltip .lu-indicator {
  margin-right: map-get($spacers, 2);
}
#license-usage-modal .release-all {
  margin-top: map-get($spacers, 2);
}
