
#user-nav .dropdown-menu {
  & .custom-icon-wrapper {
    display: inline-block;
    height: 1em;
    width: 1.25em; // matches fontawesome fw
    vertical-align: -0.125em; // matches fontawesome icon
    img {
      max-height: 100%;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
