$duke-bs-theme: light;
$duke-header-dropdown-bs-theme: light;

//  Assign to bootstrap variables
$primary: #cc0000;
$secondary: #6e7577;
$info: #118899;
$success: #69c32f;
$warning: #e68739;
$danger: #cc0000;
$light: #ffffff;
$dark: #4f5257;

$body-color: $dark;
$tooltip-bg: $info;

$duke-import-fonts: 'https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap';


$headings-font-family: "Lato", sans-serif;
$font-family-base: "Lato", sans-serif;

// Features and customized settings
$duke-logo-width-factor: 7.66667;
// $duke-color-scheme-base: #ffffff;
// $duke-body-background: #CCCCCC;
// $duke-icon-color: $primary;// #898989;
// $enable-shadows: false;

$link-color: #2a7db9;

$nav-link-color: $dark;
$nav-link-hover-color: $nav-link-color;
$nav-pills-link-active-color:       $nav-link-color;
$nav-pills-link-active-bg:          #e9e9e9;
$duke-nav-pills-link-hover-bg: transparent;
$duke-screen-logo-height: 3rem;

$duke-enable-summary-card-metadata-badges: false;

$tooltip-opacity: 1;

$focus-ring-color: #00bbcc;
$input-focus-border-color: $focus-ring-color;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$form-check-input-width: 1.25em;
$h1-font-size:                2rem;
$h2-font-size:                1.7rem;

$headings-font-weight: bold;
$btn-border-radius: 10rem;
$btn-border-radius-sm: 10rem;
$btn-border-radius-lg: 10rem;
$btn-font-size: 0.8125rem;
$btn-font-size-sm: 0.75rem;
$btn-font-size-lg: 1rem;
$btn-padding-y: 0.5rem;
$btn-padding-x: 2rem;
$btn-padding-x-sm: 1rem;
$btn-padding-x-lg: 2.5rem;
$btn-font-weight: bold;
$popover-border-color: #FFFFFF;
$duke-custom-btn-base-background: #FFFFFF;
$duke-header-bg: #333; // linear-gradient(35deg, #000 0, #333 100%);
//

$form-check-input-checked-bg-color: $info;
// $custom-control-indicator-active-bg: lighten($info, 35%) !default;
// $custom-control-indicator-checked-disabled-bg: rgba($info, 0.5);

$duke-alert-icon-bg-factor: 0;
$duke-enable-status-badges: false;

$duke-object-state-mapping: (
        invitation: (
                expired: danger,
                revoked: danger,
                declined: danger,
                updated: warning,
                unsent: warning,
                accepted: success,
                pending: secondary,
        ),
        license: (
                invalid: danger,
                deactivated: danger,
                expiring: warning,
                scheduled: info,
                valid: success,
        ),
        user: (
                suspended: danger,
                inactive: warning,
                admin: info,
                member: success,
        ),
        group: (
                employees: success,
                licenseConsumers: info,
                other: secondary,
        ),
        role: (
                orgadmin: info,
                basic-role: secondary,
        ),
);

@import "../duke-brand/variables";

