
.invalid-feedback,
.info-feedback,
.valid-feedback,
.no-data-indicator-cell,
.no-data {
  .icon {
    color: inherit;
    .two-tone-alt-color {
      color: inherit;
    }
  }
}
.duke-tool-cell .icon {
  --duke-icon-color: var(--#{$prefix}table-color);
  --duke-icon-alt-color: var(--#{$prefix}table-color);
}
// override custom button icon colors to follow btn color
@each $color, $value in $theme-colors {
  body .btn.custom-base.btn-#{$color} {
    .icon {
      color: inherit;
      .two-tone-alt-color {
        color: inherit;
      }
    }
    &:hover,
    &:focus,
    &:active {
      .icon {
        color: inherit;
        .two-tone-alt-color {
          color: inherit;
        }
      }
    }
  }
}
