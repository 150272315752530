@import "../../../scss/styles";
/**
 * Defines the minimum width for the cards displayed ont he dashboard
 */
$duke-dashboard-card-min-width: 20rem !default;

/**
 * Toggles between BS badge styles and a "state colored bullet + label" style for status badges
 */
$duke-enable-status-badges: true!default;

#dashboard-page {
  .editOrgLink {
    @extend .btn-unstyled;
    .icon {
      font-size: 50%;
      margin-bottom: 1em;
    }
  }
  button.editOrgLink {
    // same element as above, but this selector path should override and ensure that link colors are set after buttons style have been removed
    @extend .link;
  }

  .dashboard-content {
    @extend .row;
    @extend .g-4;
  }
  .dashboard-content .item {
    @extend .col;
    min-width: $duke-dashboard-card-min-width;
    .card {
      height: 100%;
      .card-footer {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }

  .card-body,
  .list-group {
    min-height: $line-height-base * $font-size-base * 6;
  }
  .card-footer {
    min-height: $line-height-base * $font-size-base;
    & *:last-child {
      margin-bottom: 0;
    }
  }
  .status-badge {
    @if ($duke-enable-status-badges) {
      border-radius: var(--bs-border-radius-pill);
      padding: 0.5em;
    }
    .badge-label {
      @include visually-hidden();
    }
  }
}
