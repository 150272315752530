@import "src/scss/styles";
#licenses-page {
  tr {
    &.invalid,
    &.deactivated,
    &.scheduled
    {
      cursor: not-allowed;
    }

    &.invalid
    {
      $sc: map-get(map-get($duke-object-state-mapping, license), invalid);
      @extend .table-#{$sc};
    }
    &.deactivated {
      $sc: map-get(map-get($duke-object-state-mapping, license), deactivated);
      @extend .table-#{$sc};
    }

    &.expiring {
      $sc: map-get(map-get($duke-object-state-mapping, license), expiring);
      @extend .table-#{$sc};
    }

    &.scheduled {
      $sc: map-get(map-get($duke-object-state-mapping, license), scheduled);
      @extend .table-#{$sc};
    }
  }
}
