@import "../../../scss/configurations";
.create-device-client-invitation-modal {
  a.inherit {
    color: inherit;
    text-decoration: inherit;
  }
  .recipient-list-item {
    margin-bottom: map-get($spacers, 2);
    display: flex;
    align-items: stretch;
    & > span {
      @include text-truncate();
      margin-right: map-get($spacers, 2);
    }
    .btn-group {
      margin-left: auto;
    }

    .control-wrapper
    {
      width: 100%;
      margin-right: map-get($spacers, 3);
    }
  }
  .input-group-input-wrapper {
    min-width: 10%;
    transition: min-width 150ms ease-out;
  }
  .input-group-input-wrapper:focus-within {
    min-width: 60%;
  }
  .recipients-heading {
    display: flex;
    flex-direction: row;
    margin-bottom: map-get($spacers, 1);
    align-items: center;
    h4 {
      margin-bottom: 0;
      &.has-errors {
        color: $danger;
      }
      .icon {
        margin-left: map-get($spacers, 2);
      }
    }
    .import-button-holder,
    .import-button {
      margin-left: auto;
    }
    .import-button-holder > .import-button {
      margin-left: 0;
    }
  }

  .recipients {
    margin-top: map-get($spacers, 3);
  }
  .new-recipient {
    display: flex;
    margin-top: map-get($spacers, 3);
    padding-top: map-get($spacers, 2);
    .input-group {
      margin-bottom: map-get($spacers, 3);
    }
    .control-wrapper
    {
      width: 100%;
      margin-right: map-get($spacers, 3);
    }
    .button-wrapper {
      padding-top: map-get($spacers, 4);
      padding-bottom: map-get($spacers, 4);
      margin-top: map-get($spacers, 1);
      .btn {
        height: 100%;
      }
    }
  }
  .ci-feedback-btn {
    float: right;
    margin-top: map-get($spacers, 2);
  }

  #missing-data,
  #duplicated-data {
    .content {
      margin-bottom: map-get($spacers, 3);
      h6 {
        display: flex;
        flex-direction: row;
        align-items: center;
        .btn {
          margin-left: auto;
        }
      }
      .list-unstyled {
        color: $danger;
      }
      .toggle-all {
        display: block;
        text-align: center;
      }
    }
  }






  .list-unstyled .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: unset;
    flex-grow: 1;
    padding-top: map-get($spacers, 1);
    margin-top: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);
    & > * {
      flex-grow: 1;
    }
    .btn
    {
      height: 100%;
    }
    .btn:first-child:not(:only-child),
    .tooltip-disabled-wrapper:first-child:not(:only-child) .btn {
      border-radius: $btn-border-radius $btn-border-radius 0 0;
    }
    .btn:last-child:not(:only-child),
    .tooltip-disabled-wrapper:last-child:not(:only-child) .btn {
      border-radius: 0 0 $btn-border-radius $btn-border-radius ;
    }
  }
  form.d-flex.collapse {
    display: none!important;
    transform: translateY(-100%);
    &.show {
      display: flex!important;
      transform: translateY(0%);
      @include transition(transform .15s ease-out);

    }
  }
  .collapsed-add-item {
    width: 100%;
    &.invalid
    {
      font-style: italic;
      color: $danger;
    }
    & > .close-edit-to-activate {
      padding: map-get($spacers, 2);
      text-align: center;
      border: $border-width solid $border-color;
      color: $text-muted;
      background: rgba(var(--#{$prefix}body-color-rgb), 0.025);
      font-size: $small-font-size;
      @include border-radius();
    }
  }
}
