@import "../../../scss/configurations";
#device-clients-page tr.suspended {
  text-decoration: line-through;
}
#device-clients-page tr.invitation {
  color: $text-muted;
}
#device-clients-page tr.invitation {
  &.revoked,
  &.declined {
    color: $danger;
  }
  &.created {
    color: $warning;
  }
}
