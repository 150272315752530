@import "../../scss/configurations";
/**
 * Horizontal padding for the site content
 */
$duke-page-padding-x: map-get($spacers, 4) !default;
#notifications {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  // text-align: right;
  padding-right: $duke-page-padding-x;
  z-index: $zindex-popover;
  .alert {
    display: inline-block;
    order: 2;
  }
  & .spinner {
    margin-bottom: $alert-margin-bottom;
  }
}
