article.page > header > h1 {
  text-transform: uppercase;
}
.duke-table .table th {
  font-weight: 400;
  text-transform: uppercase;
}
.modal .modal-title {
  text-transform: uppercase;
}
