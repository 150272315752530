@import "../../../scss/configurations";
.create-invitation-modal {
  a.inherit {
    color: inherit;
    text-decoration: inherit;
  }
  .recipient-list-item {
    margin-bottom: map-get($spacers, 2);
    display: flex;
    align-items: center;
    & > span {
      @include text-truncate();
      margin-right: map-get($spacers, 2);
    }
    .btn-group {
      margin-left: auto;
    }
  }
  .input-group-input-wrapper {
    min-width: 10%;
    transition: min-width 150ms ease-out;
  }
  .input-group-input-wrapper:focus-within {
    min-width: 60%;
  }
  .recipients-heading {
    display: flex;
    flex-direction: row;
    margin-bottom: map-get($spacers, 1);
    align-items: center;
    h4 {
      margin-bottom: 0;
      &.has-errors {
        color: $danger;
      }
      .icon {
        margin-left: map-get($spacers, 2);
      }
    }
    .import-button-holder,
    .import-button {
      margin-left: auto;
    }
    .import-button-holder > .import-button {
      margin-left: 0;
    }
  }

  .recipients {
    margin-top: map-get($spacers, 3);
  }
  .new-recipient {
    display: block;
    margin-top: map-get($spacers, 3);
    padding-top: map-get($spacers, 2);
    .input-group {
      margin-bottom: map-get($spacers, 3);
    }
  }
  .ci-feedback-btn {
    float: right;
    margin-top: map-get($spacers, 2);
  }

  #missing-data,
  #duplicated-data {
    .content {
      margin-bottom: map-get($spacers, 3);
      h6 {
        display: flex;
        flex-direction: row;
        align-items: center;
        .btn {
          margin-left: auto;
        }
      }
      .list-unstyled {
        color: $danger;
      }
      .toggle-all {
        display: block;
        text-align: center;
      }
    }
  }
}
