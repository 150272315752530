@import "../../../scss/configurations";
#manage-group-members-modal {
  .link-holder {
    margin-right: map-get($spacers, 2);

    strong {
      margin-right: map-get($spacers, 1);
    }
  }
}
