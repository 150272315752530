@import "../../../scss/styles";
#client-licenses-modal {
  .name-column {
    @extend .w-xl-60;
    @extend .w-lg-50;
    @extend .w-md-40;
    @extend .w-sm-33;
    .link-holder {
      margin-right: map-get($spacers, 2);
    }
  }
  .usage-details {
    font-size: 60%;
    &.no-usage {
      font-size: 100%;
      &,
      & .icon {
        color: var(--#{$prefix}danger-text-emphasis);
      }
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    strong {
      color: var(--#{$prefix}success-text-emphasis);
      margin-right: map-get($spacers, 1);
    }
    & + .usage-details {
      margin-top: map-get($spacers, 2);
    }
    .tooltip & {
      font-size: 100%;
    }
  }
}
