@import "../../../scss/configurations";
.filter-tools .dropdown-toggle:after {
  display: none;
}

.filter-tools .dropdown-menu {
  max-height: 20rem;
  overflow: auto;
}
.filter-tools .dropdown-header {
  margin-top: -1 * $dropdown-padding-y;
  padding-top: $dropdown-padding-y + nth($dropdown-header-padding, 1);
  background: $dropdown-bg;
  position: sticky;
  top: -1 * $dropdown-padding-y;
  z-index: 10;
}
