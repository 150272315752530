@import "../../../scss/configurations";
#no-organization {
  text-align: center;
  h1 {
    color: $danger;
  }
  h1 .icon {
    margin-right: map-get($spacers, 2);
  }
}
