@import "../../scss/configurations";
.action-error-notification {
  width: 32em;
  max-width: calc(
    100vw - #{map-get($spacers, 4) * 2} - #{map-get($spacers, 2) * 2}
  );

  textarea.details.form-control {

    width: 100%;
    height: unset;
    max-height: 15em;
    overflow: auto;
    resize: none;
    &:not(.show) {
      height: 0;
    }
  }
  .form-group {
    min-height: 1.5em;
  }
  .form-group *[readonly] ~ .form-label.toggle-details
  {
    background-color: var(--bs-alert-bg);
    &:before {
      z-index: 0;
    }
    a {
      position: relative;
      z-index: 1;
    }

  }
  .form-control:focus ~ .form-label {
    background-color: var(--bs-alert-bg);
    box-shadow: 0px
        (($input-font-size * 0.75 * $input-line-height * 0.5) - 0.2rem) 0px 0px var(--bs-alert-bg) inset, 0 ($input-focus-width * 0.25) 0 $input-focus-width $input-btn-focus-color inset;
  }
  .collapse:not(.show) + .toggle-details {
    top: 0;
    left: 0;
    padding-left: 0;
  }
}
