@import "../../../scss/configurations";
#api-version-error-screen {
  .details {
    margin-bottom: map-get($spacers, 4);
    text-align: left;
    pre,
    code {
      font-size: $font-size-sm;
    }
    .api {
      & > *:last-child {
        margin-bottom: 0;
      }
      & + .api {
        border-top: $border-width solid $border-color;
        padding-top: map-get($spacers, 4);
      }
    }
    & .btn {
      &.active,
      &:hover,
      &:focus {
        border-color: $border-color;
        background-color: color-mix(in srgb, var(--bs-body-bg), var(--bs-body-color) 5%);
      }
    }
    & .btn.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    & .collapsible-target {
      margin-top: -1 * $border-width;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border: $border-width solid $border-color;
      padding: map-get($spacers, 4) map-get($spacers, 2);
      margin-bottom: map-get($spacers, 2);
      background-color: color-mix(in srgb, var(--bs-body-bg), var(--bs-body-color) 2.5%);
    }
  }
  h1,
  .icon {
    color: var(--#{$prefix}danger-text-emphasis);
  }
}
