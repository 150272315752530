@import "../../../scss/configurations";

/**
 * Toggles between BS badge styles and a "state colored bullet + label" style for status badges
 */
$duke-enable-status-badges: true!default;
.status-badge.license {
  $states: invalid, deactivated, expiring, scheduled, valid;
  $colors: map-get($duke-object-state-mapping, license);
  @each $state in $states {
    &.#{$state} {
      $c: map-get($colors, $state);
      @if ($duke-enable-status-badges) {
        background-color: rgb(var(--#{$prefix}#{$c}-rgb));
        color: var(--duke-#{$c}-fg, var(--#{$prefix}badge-color));
      } @else {
        &:before {
          background-color: rgb(var(--#{$prefix}#{$c}-rgb));
        }
      }
    }
  }
}
