/**
 * This could be a component style, but is currently used from multiple components as "shared" dom pattern.
 * TODO: low priority refactor
 */
.duke-table .usage-details {
  font-size: 60%;
  position: relative;

  .usage-details-content {
    border-radius: $btn-border-radius-sm;
  }

  .release + .usage-details-content,
  .tooltip-disabled-wrapper + .usage-details-content {
    padding-left: map-get($spacers, 1);
    padding-right: calc(map-get($spacers, 1) + 1.875em); // 1.875em matches the release button exactly
    border-radius: $btn-border-radius-sm;
    border: 1px solid transparent;
  }


  &.to-be-removed {
    .release,
    .release:hover,
    .release:active,
    .release:focus,
    .release + .usage-details-content,
    .release:hover + .usage-details-content,
    .release:active + .usage-details-content,
    .release:focus + .usage-details-content,
    .tooltip-disabled-wrapper + .usage-details-content,
    .tooltip-disabled-wrapper:hover + .usage-details-content,
    .tooltip-disabled-wrapper:active + .usage-details-content,
    .tooltip-disabled-wrapper:focus + .usage-details-content {
      border: 1px solid rgb(var(--#{$prefix}danger-rgb));
      text-decoration: line-through;

    }
    .release,
    .release:hover,
    .release:active,
    .release:focus
    {
      $tmpbg: $danger;
      // Override button color, if color is same as card bg
      @if $danger == $card-bg {
        $tmpbg: darken($danger, 5%);
      }
      // override text color for contrast
      background: $tmpbg;
      color: color-contrast($danger);
    }
  }

  .release
  {
    border-radius: 0 $btn-border-radius-sm $btn-border-radius-sm 0;
    position: absolute;
    right: 0;
    font-size: 1.5em;
    top: 0;
    bottom: 0;
    color: $text-muted;
    border: 1px solid transparent;

    &:hover,
    &:active,
    &:focus {

      $tmpbg: $secondary;
      // Override button color, if color is same as card bg
      @if $secondary == $card-bg {
        $tmpbg: darken($secondary, 5%);
      }
      background: $tmpbg;
      color: color-contrast($secondary);

      & + .usage-details-content {
          background: rgba(0, 0, 0, 0.05);
        border: 1px solid $secondary;
      }
    }
  }
  .tooltip-disabled-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1.875em; // matches the release button exactly
    &:hover,
    &:active,
    &:focus {
      & + .usage-details-content {
        background: rgba(0, 0, 0, 0.05);
        border: 1px solid $secondary;
      }
    }
  }
  & + .usage-details {
    margin-top: map-get($spacers, 2);
  }
}
.tooltip .usage-details {
  font-size: 100%;
}

