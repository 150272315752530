@import "../../../scss/configurations";
#view-invitation-modal {
  .indicator {
    &.indicator-active {
      color: $success;
    }

    &.indicator-inactive {
      color: $danger;
    }
  }
}
