@import "../../../scss/configurations";
#error-screen {
  .details {
    margin-bottom: map-get($spacers, 2);
    .collapsible-target {
      color: $text-muted;
      font-family: $font-family-monospace;
      font-size: $small-font-size;
      padding: map-get($spacers, 4) map-get($spacers, 2);
      background: var(--#{$prefix}body-bg);
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  h1,
  .icon {
    color: var(--#{$prefix}danger-text-emphasis);
  }
}
