@import "../../../scss/configurations";
#organization-card {
  .description .field-value {
    max-height: calc(
      #{$line-height-base} * 3em + (#{map-get($spacers, 1)} * 2)
    );
    font-size: $small-font-size;
    overflow: auto;
  }
}
