/**
 * We don't support removing elements easily, but setting empty copy is trivial, so we hide these copy items from flow
 * completely when there is no text to show.
 */
h1,
h2,
h3,
h4,
h5,
h6,
p
{
  &:empty {
    display: none;
  }
}

.link {
  @extend a;
}

.capitalize-first,
.tooltip .tooltip-inner {
  &:first-letter {
    text-transform: uppercase;
  }
}

.dropdown-menu.from-modal {
  z-index: $zindex-modal + 1;
}
body > .tooltip
{
  position: fixed;
}


/**
 * additional width utility classes
 */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .w#{$infix}-0 {
      width: 0% !important;
    }
    .w#{$infix}-25 {
      width: 25% !important;
    }
    .w#{$infix}-50 {
      width: 50% !important;
    }
    .w#{$infix}-75 {
      width: 75% !important;
    }
    .w#{$infix}-100 {
      width: 100% !important;
    }
    .w#{$infix}-33 {
      width: 33.333% !important;
    }
    .w#{$infix}-66 {
      width: 66.666% !important;
    }
    .w#{$infix}-40 {
      width: 40% !important;
    }
    .w#{$infix}-60 {
      width: 60% !important;
    }
    .w#{$infix}-70 {
      width: 60% !important;
    }
    .w#{$infix}-80 {
      width: 80% !important;
    }
  }
}
