/**
 * To override default duke-brand, simply remove the import and insert your own Bootstrap theme styles here.
 * To extend the default duke-brand, copy in the styles from "../duke-brand/theme" and edit as needed. Duke-brand
 * styles are divided into files to allow for easy pick & mix.
 *
 * See adjacent readme.md for more details
 */
@import "../duke-brand/theme";
#sidebar,
#site-nav {
  .nav-item .nav-link {
    font-weight: bold;
    text-transform: uppercase;

    &.active .icon {
      color: $primary;
    }
  }
}
@media (max-width: 991.98px) {
  body #header:not(.disabled) .navbar-collapse .active .icon {
    color: $primary;
  }
  body #header:not(.disabled) .navbar-collapse .nav-link:not(.active):focus,
  body #header:not(.disabled) .navbar-collapse .nav-link:not(.active):hover {
    background-color: transparent;
  }
}

body {
  #content #sidebar .nav .nav-link:not(.active):focus,
  #content #sidebar .nav .nav-link:not(.active):focus-within,
  #content #sidebar .nav .nav-link:not(.active):hover {
    background-color: transparent;
  }
}

body #dashboard-page button.editOrgLink {
  --bs-link-color-rgb: $body-color;
  --duke-icon-color: $body-color;
  text-decoration: none;
  vertical-align: baseline;
}

#header .navbar-brand,
body .btn {
  text-transform: uppercase;
}
#header .navbar {
  background-image: linear-gradient(35deg, #000 0, #333 100%);
}

.input-group {
  & > .form-control,
  & > .form-control-plaintext {
    border-radius: $btn-border-radius;
  }
  &.input-group-lg > .form-control,
  &.input-group-lg > .form-control-plaintext {
    border-radius: $btn-border-radius-lg;
  }
  &.input-group-sm > .form-control,
  &.input-group-sm > .form-control-plaintext {
    border-radius: $btn-border-radius-sm;
  }
}
.card-header h2 {
  text-transform: uppercase;
}
.modal-footer {
  .btn-primary,
  .btn-success,
  .btn-danger {
    @include button-variant(
                    #000000,
                    #000000,
                    #ffffff,
                    #404040,
                    #404040,
                    #ffffff,
                    #404040,
                    #404040,
                    #ffffff
    );
  }
  .btn + .btn,
  .tooltip-disabled-wrapper + .btn {
    margin-right: auto;
  }
}
.modal-header {
  display: grid;
  grid-template-columns: 1fr minmax(auto, auto) 1fr;
  justify-items: center;
  align-items: start;
  & > .btn:last-child {
    margin-left: auto;
  }
}
.modal-title {
  grid-column-start: 2;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
}
.btn {
  text-transform: uppercase;
}
.btn-primary {
  @include button-variant(
                  $primary,
                  $primary,
                  #ffffff,
                  #ef0001,
                  #ef0001,
                  #ffffff,
                  #ef0001,
                  #ef0001,
                  #ffffff
  );
}

.btn-secondary {
  @include button-variant(
                  $secondary,
                  $secondary,
                  #ffffff,
                  #82898b,
                  #82898b,
                  #ffffff,
                  #82898b,
                  #82898b,
                  #ffffff
  );

  &:hover,
  &:focus,
  &.focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: #ffffff;
  }
}
body #user-licenses-modal .duke-table .btn {
  @extend .btn-sm;
}
@include media-breakpoint-up("xl", $grid-breakpoints) {
  body #license-usage-modal .duke-table .w-xl-60,
  body #user-licenses-modal  .duke-table .w-xl-60 {
    // a tad risky but ok for now, creates sufficient space for the reserve/block buttons
    width: 57% !important;
  }
}
