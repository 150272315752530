@import "../../../scss/configurations";
#download-license-modal {
  .err-msg > strong {
    margin-right: map-get($spacers, 2);
  }
  .hardware-id {
    word-break: break-all;
  }
  .link-holder {
    margin-right: map-get($spacers, 2);

    strong {
      margin-right: map-get($spacers, 1);
    }
  }
}
