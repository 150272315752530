@import "../../../scss/configurations";

/**
 * Defines the highlight color used to indicate drag and drop
 */
$duke-dnd-target-color: $primary !default;
/**
 * Defines the highlight border used to indicate drag and drop
 */
$duke-dnd-border: 2px dashed !default;
.column-tools .dropdown-toggle:after {
  // hide the caret as we don't need it and it messes up the balance
  display: none;
}

.column-tools .dropdown-menu {
  max-height: 20rem;
  overflow: auto;
}

.column-tools-drop {
  padding: calc(#{$btn-padding-y} - 1px) calc(#{$btn-padding-x} - 1px);
  margin: (-1 * $btn-padding-y) (-1 * $btn-padding-x);
  box-sizing: border-box;
  border: $duke-dnd-border transparent;
  border-radius: inherit;
  &.is-drag-over.is-droppable {
    background: rgba($duke-dnd-target-color, 0.125);
    border-color: $duke-dnd-target-color;
    color: $duke-dnd-target-color;
    box-shadow: 0 0 5px $duke-dnd-target-color;
  }
}
