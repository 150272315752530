/**
 * Creates a borderless light-weight "tile" to use on the dashboard in place of the bootstrap card.
 */
.summary-card {
  @include box-shadow($box-shadow);
  border-color: transparent;
  border-radius: 0;
  .card-header {
    background-color: transparent;
  }
  .card-header + .card-body {
    padding-top: $card-spacer-y;
  }
  .list-group-item {
    background: $card-bg;
  }
  .card-body {
    padding-top: 0;
    padding-bottom: 0;
    &:first-child {
      padding-top: $card-spacer-y;
    }
    &:last-child {
      padding-bottom: $card-spacer-y;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
  }
  .card-footer,
  & > .list-group + .card-footer {
    border-top: $card-border-width solid $card-border-color;
    // extends the bg color to cover the card borders
    box-shadow: 1px 0 0 $card-cap-bg, -1px 0 0 $card-cap-bg,
      0 1px 0 $card-cap-bg;
  }
}
