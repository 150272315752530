@import "../../../scss/configurations";
.view-role-modal {
  .list-as-value .field-value {
    width: 100%;
    strong,
    em span {
      word-wrap: break-word;
      word-break: break-word;
    }
  }
  .form-label + .status-badge {
    margin-left: map-get($spacers, 2);
  }
}
