@import "../../../scss/styles";
#invitations-page {
  tr {
    &.expired
    {
      $sc: map-get(map-get($duke-object-state-mapping, invitation), expired);
      @extend .table-#{$sc};
    }
    &.revoked
    {
      $sc: map-get(map-get($duke-object-state-mapping, invitation), revoked);
      @extend .table-#{$sc};
    }
    &.accepted
    {
      $sc: map-get(map-get($duke-object-state-mapping, invitation), accepted);
      @extend .table-#{$sc};
    }
    &.declined
    {
      $sc: map-get(map-get($duke-object-state-mapping, invitation), declined);
      @extend .table-#{$sc};
    }
    &.updated
    {
      $sc: map-get(map-get($duke-object-state-mapping, invitation), updated);
      @extend .table-#{$sc};
    }
    &.unsent
    {
      $sc: map-get(map-get($duke-object-state-mapping, invitation), unsent);
      @extend .table-#{$sc};
    }
    &.pending,
    &.deliveryRequested,
    &.created
    {
      $sc: map-get(map-get($duke-object-state-mapping, invitation), updated);
      @extend .table-#{$sc};
    }
  }
  .indicator {
    &.indicator-active {
      &,
      & .icon {
        color: var(--#{$prefix}success-text-emphasis);
      }
    }
    &.indicator-inactive {
      &,
      & .icon {
        color: var(--#{$prefix}danger-text-emphasis);
      }
    }
  }
}
